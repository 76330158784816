@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700");
* body {
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  box-sizing: border-box !important;
}
body {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  background: #fff;
  font-size: 16px;
  word-wrap: break-word;
  color: #000;
  padding: 0 !important;
  box-sizing: border-box;
}
ul {
  list-style: none;
  padding: 0;
}
a {
  color: #2f70a0;
  text-decoration: none;
  font-weight: 400;
  outline: none !important;
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -o-transition: 1s;
  transition: 1s;
}
a:hover,
a:focus {
  color: #1d4d71;
  text-decoration: none;
}
p {
  color: #000;
}
.siteForm label {
  font-size: 12px;
  font-weight: 400;
  color: #888888;
}
.siteForm input[type="text"],
.siteForm input[type="email"],
.siteForm input[type="url"],
.siteForm input[type="number"],
.siteForm input[type="tel"],
.siteForm input[type="password"],
.siteForm input[type="file"],
.siteForm select,
.siteForm textarea {
  width: 100%;
  background: #ffffff;
  border: 1px solid #e2e2e2;
  height: 40px;
  padding: 5px 15px !important;
  font-size: 14px;
  color: #6e6e6e;
  box-shadow: none;
  outline: none;
  border-radius: 3px;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  -ms-border-radius: 0 !important;
  border-radius: 0 !important;
}
.siteForm input[type="text"]:focus,
.siteForm input[type="email"]:focus,
.siteForm input[type="url"]:focus,
.siteForm input[type="number"]:focus,
.siteForm input[type="tel"]:focus,
.siteForm input[type="password"]:focus,
.siteForm input[type="file"]:focus,
.siteForm select:focus,
.siteForm textarea:focus {
  background: #f6f6f6;
}
.siteForm textarea {
  height: 152px !important;
  padding: 10px 10px !important;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.siteForm select {
  height: 50px !important;
}
.siteForm select:focus::-ms-value {
  color: #6e6e6e;
  background: none;
}
.siteForm input[type="submit"],
.siteForm a {
  display: block;
  height: 50px;
  padding: 10px 10px !important;
  text-align: center;
  font-size: 16px;
  color: #099c97;
  border: 1px solid #4c8b89;
  font-weight: 800;
  width: 100%;
  outline: none;
  cursor: pointer;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  -ms-border-radius: 0 !important;
  border-radius: 0 !important;
}
.siteForm input[type="submit"]:hover,
.siteForm input[type="submit"]:focus,
.siteForm a:hover,
.siteForm a:focus {
  color: #fff;
}
.siteForm .form-text {
  font-size: 12px;
  margin-top: 4px;
}
.siteBtn {
  background: -webkit-linear-gradient(
    left,
    #315a8e 0%,
    #2a90b8 60%,
    #2999be 100%
  );
  background: -o-linear-gradient(left, #315a8e 0%, #2a90b8 60%, #2999be 100%);
  background: -ms-linear-gradient(left, #315a8e 0%, #2a90b8 60%, #2999be 100%);
  background: -moz-linear-gradient(left, #315a8e 0%, #2a90b8 60%, #2999be 100%);
  background: linear-gradient(to right, #315a8e 0%, #2a90b8 60%, #2999be 100%);
  -webkit-border-radius: 25px !important;
  -moz-border-radius: 25px !important;
  -ms-border-radius: 25px !important;
  border-radius: 25px !important;
  padding: 12px 30px;
  min-width: 130px;
  display: inline-block;
  font-size: 14px;
  color: #fff;
  border: none;
  text-align: center;
  font-weight: 400;
  outline: none;
}
.siteBtn:hover {
  color: #fff;
}
.siteBtn:focus {
  color: #fff;
}
.siteBtnGr {
  background: -webkit-linear-gradient(
    351deg,
    rgba(52, 95, 142, 0.74) 0%,
    rgba(49, 97, 148, 0.74) 3%,
    rgba(43, 143, 183, 0.74) 64%,
    rgba(41, 153, 189, 0.74) 100%
  );
  background: -o-linear-gradient(
    351deg,
    rgba(52, 95, 142, 0.74) 0%,
    rgba(49, 97, 148, 0.74) 3%,
    rgba(43, 143, 183, 0.74) 64%,
    rgba(41, 153, 189, 0.74) 100%
  );
  background: -ms-linear-gradient(
    351deg,
    rgba(52, 95, 142, 0.74) 0%,
    rgba(49, 97, 148, 0.74) 3%,
    rgba(43, 143, 183, 0.74) 64%,
    rgba(41, 153, 189, 0.74) 100%
  );
  background: -moz-linear-gradient(
    351deg,
    rgba(52, 95, 142, 0.74) 0%,
    rgba(49, 97, 148, 0.74) 3%,
    rgba(43, 143, 183, 0.74) 64%,
    rgba(41, 153, 189, 0.74) 100%
  );
  background: linear-gradient(
    99deg,
    rgba(52, 95, 142, 0.74) 0%,
    rgba(49, 97, 148, 0.74) 3%,
    rgba(43, 143, 183, 0.74) 64%,
    rgba(41, 153, 189, 0.74) 100%
  );
}
.siteBtnGr:hover {
  color: #fff;
}
.siteBtnGr:focus {
  color: #fff;
}
.siteTable {
  margin-bottom: 30px;
}
.siteTable th {
  font-size: 12px;
  font-weight: 500;
  background: #f5f5f5;
  color: #f15922;
}
.siteTable td {
  font-size: 12px;
}
.siteTable .dtr-title {
  font-weight: 500;
  padding-right: 10px;
}
#customerTable_filter label {
  font-size: 14px;
  color: #ccc;
}
.siteHeader.headerSchroll .headerContainer {
  padding: 12px 15px;
}
.siteHeader {
  width: 100%;
  background: #fff;
  z-index: 88;
  top: 0;
  position: fixed;
  display: block;
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -o-transition: 1s;
  transition: 1s;
  border-bottom: 1px solid #eee;
}
.siteHeader:after {
  clear: both;
  display: block;
  content: "";
}
.siteHeader .headerContainer {
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -o-transition: 1s;
  transition: 1s;
  padding: 18px 15px;
}
@media (max-width: 576px) {
  .siteHeader .headerContainer {
    padding: 12px 15px;
  }
}
.siteLogo {
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -o-transition: 1s;
  transition: 1s;
  width: 175px;
  float: left;
  margin: 0;
  padding: 0;
  font-size: 12px;
}
@media (max-width: 767px) {
  .siteLogo {
    width: 130px;
  }
}
.siteLogo img {
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -o-transition: 1s;
  transition: 1s;
  width: 100%;
  height: auto;
}
.headerSearch {
  float: right;
  margin-top: 5px;
  margin-left: 10px;
}
.headerSearch a {
  font-size: 16px;
  color: #fff;
}
.searchBox {
  width: 200px;
  border-radius: 3px;
  overflow: hidden;
  float: right;
  border: 1px solid #ccc;
  margin-right: 25px;
  position: relative;
}
@media (max-width: 992px) {
  .searchBox {
    float: left;
    margin-right: 0;
  }
}
.searchBox input[type="search"] {
  width: 100%;
  height: 26px;
  border: none;
  border-radius: 3px;
  font-size: 14px;
  padding: 3px 20px 3px 10px;
  float: left;
  outline: none;
}
.searchBox input[type="button"] {
  position: absolute;
  right: 2px;
  top: 2px;
  width: 20px;
  height: 20px;
  /* background: url(./images/search-icon.png) no-repeat; */
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 3px;
}
.userDropdown {
  float: right;
  margin-top: 10px;
}
@media (max-width: 992px) {
  .userDropdown {
    margin-right: 40px;
  }
}
@media (max-width: 767px) {
  .userDropdown {
    margin-top: 9px;
  }
}
@media (max-width: 576px) {
  .userDropdown {
    margin-top: 7px;
  }
}
.userDropdown .dropdown-toggle {
  background: none;
  outline: none;
  border: none;
  font-size: 22px;
  color: #3f5c7a;
  cursor: pointer;
}
.userDropdown .dropdown-toggle:hover {
  color: #d52950;
}
.userDropdown .dropdown-toggle:after {
  font-size: 18px;
}
.userDropdown.show .dropdown-toggle {
  color: #d52950;
}
.userDropdown .dropdown-menu {
  background: #3f5c7a;
  padding: 5px 5px;
}
.userDropdown .dropdown-menu ul {
  margin: 0;
}
.userDropdown .dropdown-menu ul li {
  border-bottom: 1px solid #5e7c9c;
}
.userDropdown .dropdown-menu ul li:last-child {
  border-bottom: none;
}
.userDropdown .dropdown-menu ul li a {
  display: block;
  text-align: center;
  padding: 8px 5px;
  font-size: 14px;
  color: #fff;
  font-weight: 500;
}
.userDropdown .dropdown-menu ul li a:hover {
  background: #314b66;
}
.mainNav {
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -o-transition: 1s;
  transition: 1s;
  display: block;
  list-style: none;
  margin: 12px 0 0 0;
  padding: 0;
  float: right;
}
@media (max-width: 992px) {
  .mainNav {
    display: none;
  }
}
.mainNav ul {
  padding: 0;
  list-style: none;
  display: block;
  margin: 0;
}
.mainNav ul li {
  display: inline-block;
  padding: 0;
  margin: 0;
  position: relative;
}
.mainNav ul li a {
  display: inline-block;
  padding: 5px 15px;
  font-size: 14px;
  font-weight: 500;
  color: #3f5c7a;
}
@media (max-width: 1200px) {
  .mainNav ul li a {
    font-size: 14px;
    padding: 10px 8px;
  }
}
.mainNav ul li a:hover {
  color: #d52950;
}
.mainNav ul li a.active {
  color: #d52950;
}
.mainNav ul li:hover .sub-menu {
  display: block;
}
.mainNav ul li:last-child a span {
  font-size: 20px;
}
.loginBtn {
  display: none;
  float: right;
  margin-right: 40px;
  margin-top: 3px;
}
@media (max-width: 767px) {
  .loginBtn {
    display: block;
  }
}
.loginBtn a {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  background: #f15922;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  border-radius: 5px !important;
  padding: 7px 15px;
}
.loginBtn a:hover {
  color: #fff;
}
.hamburgerSideNav {
  position: fixed !important;
  z-index: 9999;
  right: 15px;
  top: 30px;
  display: none;
}
@media (max-width: 992px) {
  .hamburgerSideNav {
    display: block;
  }
}
@media (max-width: 767px) {
  .hamburgerSideNav {
    top: 30px;
  }
}
@media (max-width: 576px) {
  .hamburgerSideNav {
    top: 25px;
  }
}
.sideBar {
  width: 300px;
  height: 100%;
  padding-top: 0;
  position: fixed;
  top: 0;
  padding-top: 50px;
  background: #3f5c7a;
  border: 1px solid #6583a1;
  overflow-y: auto;
  z-index: 99;
  transition: all 0.75s cubic-bezier(0.75, 0.19, 0.24, 1);
}
#navSidebar {
  right: -300px;
}
#navSidebar.open {
  right: -2px;
}
@media (max-width: 576px) {
  #navSidebar.open {
    width: 100%;
    right: 0;
  }
}
.sideNav {
  margin: 30px 0;
}
.sideNav ul {
  padding: 0 20px 0 20px;
  margin: 0;
}
.sideNav ul li {
  display: block;
  text-align: center;
  border-bottom: 1px solid #4d7095;
}
.sideNav ul li a {
  display: block;
  color: #fff;
  padding: 15px 10px;
  text-align: left;
  font-size: 16px;
  text-align: center;
  font-weight: 400;
}
.sideNav ul li a:hover {
  color: #1f3852;
}
.sideNav ul li a.active {
  color: #1f3852;
}
#hamburgerIcon {
  width: 30px;
  height: 35px;
  position: relative;
  z-index: 9999;
  cursor: pointer;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}
#hamburgerIcon span {
  display: block;
  position: absolute;
  height: 4px;
  width: 50%;
  background: #3f5c7a;
  opacity: 1;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
#hamburgerIcon.open span {
  background: #fff;
}
#hamburgerIcon span:nth-child(even) {
  left: 50%;
  border-radius: 0 9px 9px 0;
}
#hamburgerIcon span:nth-child(odd) {
  left: 0px;
  border-radius: 9px 0 0 9px;
}
#hamburgerIcon span:nth-child(1),
#hamburgerIcon span:nth-child(2) {
  top: 0px;
}
#hamburgerIcon span:nth-child(3),
#hamburgerIcon span:nth-child(4) {
  top: 8px;
}
#hamburgerIcon span:nth-child(5),
#hamburgerIcon span:nth-child(6) {
  top: 16px;
}
#hamburgerIcon.open span:nth-child(1),
#hamburgerIcon.open span:nth-child(6) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
#hamburgerIcon.open span:nth-child(2),
#hamburgerIcon.open span:nth-child(5) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
#hamburgerIcon.open span:nth-child(1) {
  left: 5px;
  top: 5px;
}
#hamburgerIcon.open span:nth-child(2) {
  left: calc(50% - 5px);
  top: 5px;
}
#hamburgerIcon.open span:nth-child(3) {
  left: -50%;
  opacity: 0;
}
#hamburgerIcon.open span:nth-child(4) {
  left: 100%;
  opacity: 0;
}
#hamburgerIcon.open span:nth-child(5) {
  left: 5px;
  top: 10px;
}
#hamburgerIcon.open span:nth-child(6) {
  left: calc(50% - 5px);
  top: 10px;
}
body.overflohidden {
  overflow: hidden;
}
.mainWrapper {
  background: #fff;
  overflow: hidden;
}
.mainWrapper.open {
  opacity: 0.4 !important;
}
.pageContainer {
  padding: 120px 15px 50px 15px;
}
.sectionTop {
  margin-bottom: 50px;
  text-align: center;
}
@media (max-width: 767px) {
  .sectionTop {
    margin-bottom: 30px;
  }
}
.sectionTop h2 {
  font-size: 36px;
  color: #1a1a23;
  margin: 0;
  padding: 0 0 20px 0;
  position: relative;
}
@media (max-width: 767px) {
  .sectionTop h2 {
    font-size: 24px;
  }
}
.sectionTop h2:after {
  display: block;
  content: "";
  position: absolute;
  width: 70px;
  height: 4px;
  background: #f15922;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 0;
}
.sectionContainer {
  padding: 60px 15px;
}
@media (max-width: 767px) {
  .sectionContainer {
    padding: 40px 15px;
  }
}
.whyJoinUs {
  background: #3f5c7a;
  color: #ffffff;
  padding: 50px 30px;
  text-align: center;
}
@media (max-width: 767px) {
  .whyJoinUs {
    padding: 30px 15px;
  }
}
.whyJoinUs h2 {
  font-size: 22px;
  color: #fff;
  font-weight: 500;
  margin: 0 0 25px 0;
}
@media (max-width: 767px) {
  .whyJoinUs h2 {
    font-size: 18px;
  }
}
.whyJoinUs p {
  max-width: 450px;
  display: inline-block;
  color: #ffffff;
  font-size: 12px;
  margin: 0 0 15px 0;
}
.aboutUsTabs {
  background: #f4eae0 url("./images/about-tabs-bg.jpg") no-repeat;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 50px 30px 35px 30px;
}
@media (max-width: 767px) {
  .aboutUsTabs {
    padding: 40px 15px 25px 15px;
  }
}
.aboutUsTabs .astContentSection {
  max-width: 600px;
  margin: 0 auto;
  margin-bottom: 50px;
}
.aboutUsTabs .astContentSection .astContentImage {
  max-width: 205px;
}
@media (max-width: 767px) {
  .aboutUsTabs .astContentSection .astContentImage {
    margin: 0 auto;
    margin-bottom: 20px;
  }
}
.aboutUsTabs .astContentSection .astContentImage img {
  width: 100%;
  height: auto;
}
@media (max-width: 767px) {
  .aboutUsTabs .astContentSection .astContent {
    text-align: center;
  }
}
.aboutUsTabs .astContentSection .astContent strong {
  font-size: 22px;
  color: #3f5c7a;
  display: block;
  margin-bottom: 10px;
  font-weight: 500;
}
.aboutUsTabs .astContentSection .astContent p {
  font-size: 13px;
  line-height: 22px;
  color: #3f5c7a;
  margin-bottom: 0;
}
.aboutUsTabs .aboutUsTabsNav {
  font-size: 0;
}
.aboutUsTabs .aboutUsTabsNav li {
  display: inline-block;
  width: 33.3333%;
  font-size: 0;
}
.aboutUsTabs .aboutUsTabsNav li a {
  position: relative;
  padding: 20px;
  background: #ffffff;
  color: #3f5c7a;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
}
@media (max-width: 576px) {
  .aboutUsTabs .aboutUsTabsNav li a {
    padding: 10px;
  }
}
.aboutUsTabs .aboutUsTabsNav li a.active {
  background: #3f5c7a;
  color: #fff;
}
.aboutUsTabs .aboutUsTabsNav li a.active:before {
  display: block;
  content: "";
  position: absolute;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #3f5c7a;
  top: -10px;
  left: 50%;
  margin-left: -5px;
}
.ourProcess {
  background: #f4ece3;
  color: #ffffff;
  padding: 50px 30px;
  text-align: center;
}
@media (max-width: 767px) {
  .ourProcess {
    padding: 30px 15px;
  }
}
.ourProcess h2 {
  font-size: 22px;
  color: #3f5c7a;
  font-weight: 500;
  margin: 0 0 25px 0;
}
@media (max-width: 767px) {
  .ourProcess h2 {
    font-size: 18px;
  }
}
.ourProcess p {
  max-width: 450px;
  display: inline-block;
  color: #3f5c7a;
  font-size: 12px;
  margin: 0 0 15px 0;
}
.processTabsSection {
  background: #3f5c7a url("./images/process-tabs-bg.jpg") no-repeat;
  background-size: cover;
}
.processTabsSection .processTabsNav {
  font-size: 0;
  padding: 20px;
}
@media (max-width: 576px) {
  .processTabsSection .processTabsNav {
    padding: 20px 0;
  }
}
.processTabsSection .processTabsNav li {
  display: inline-block;
  width: 100%;
  font-size: 0;
  margin-bottom: 2px;
}
@media (max-width: 767px) {
  .processTabsSection .processTabsNav li {
    width: 48%;
    margin: 0 1% 3px 1%;
  }
  .processTabsSection .processTabsNav li:last-child {
    width: 100%;
  }
}
.processTabsSection .processTabsNav li a {
  position: relative;
  padding: 20px;
  background: rgba(255, 255, 255, 0.5);
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
}
@media (max-width: 576px) {
  .processTabsSection .processTabsNav li a {
    padding: 10px;
  }
}
.processTabsSection .processTabsNav li a.active {
  background: #fff;
  color: #3f5c7a;
}
.processTabsSection .processTabsNav li a.active:before {
  display: block;
  content: "";
  position: absolute;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #fff;
  left: -10px;
  top: 50%;
  margin-top: -5px;
}
@media (max-width: 767px) {
  .processTabsSection .processTabsNav li a.active:before {
    display: none;
  }
}
.processTabsSection .processTabsContentSection {
  padding: 50px 15px;
}
@media (max-width: 576px) {
  .processTabsSection .processTabsContentSection {
    padding: 20px 0;
  }
}
.processTabsSection .processTabsContentSection .processtabContent {
  max-width: 400px;
  margin: 0 auto;
}
.processTabsSection .processTabsContentSection .processtabContent strong {
  font-size: 22px;
  color: #fff;
  display: block;
  margin-bottom: 10px;
  font-weight: 500;
}
.processTabsSection .processTabsContentSection .processtabContent p {
  font-size: 13px;
  line-height: 22px;
  color: #fff;
  margin-bottom: 0;
}
.categoryTabsNavSec {
  background: #284473;
}
.categoryTabsNavSec .categoryTabsNav {
  font-size: 0;
  padding: 0;
}
.categoryTabsNavSec .categoryTabsNav li {
  display: inline-block;
  width: 33.3333%;
  font-size: 14px;
}
.categoryTabsNavSec .categoryTabsNav li a {
  display: block;
  background: #fff;
  min-height: 150px;
  padding: 30px 15px;
  text-align: center;
  border: 1px solid #eee;
}
@media (max-width: 576px) {
  .categoryTabsNavSec .categoryTabsNav li a {
    padding: 15px 15px;
  }
}
.categoryTabsNavSec .categoryTabsNav li a.active {
  background: none;
}
.categoryTabsNavSec .categoryTabsNav li a.active strong {
  color: #fff;
}
.categoryTabsNavSec .categoryTabsNav li a .catIcon {
  width: 60px;
  display: inline-block;
  margin-bottom: 5px;
}
.categoryTabsNavSec .categoryTabsNav li a .catIcon img {
  width: 100%;
  height: auto;
}
.categoryTabsNavSec .categoryTabsNav li a strong {
  display: block;
  font-size: 14px;
  color: #3f5c7a;
  font-weight: 500;
}
@media (max-width: 576px) {
  .categoryTabsNavSec .categoryTabsNav li a strong {
    font-size: 12px;
  }
}
.categoryTabsContentSec {
  background: #3f5c7a url("./images/cat-detail-bg.jpg") no-repeat center center;
  background-size: cover;
  padding: 40px 15px;
}
.categoryTabsContentSec .tab-content {
  width: 100%;
}
@media (max-width: 767px) {
  #categorySection {
    display: none;
  }
}
.categorySliderWrap {
  background: #3f5c7a url("./images/cat-detail-bg.jpg") no-repeat center center;
  background-size: cover;
  padding: 40px 0;
  display: none;
}
@media (max-width: 767px) {
  .categorySliderWrap {
    display: block;
  }
}
.categorySliderWrap .owl-dots {
  margin: 30px 0 0 0 !important;
}
.categorySliderWrap .owl-dots .owl-dot span {
  width: 8px !important;
  height: 8px !important;
  background: #6e8dc0 !important;
  border: none;
  margin: 0 4px !important;
}
.categorySliderWrap .owl-dots .owl-dot.active span,
.categorySliderWrap .owl-dots .owl-dot:hover span {
  background: #b2c6e7 !important;
}
.categoryTabsContentInner {
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
}
.categoryTabsContentInner .catIcon {
  width: 60px;
  display: inline-block;
  margin-bottom: 5px;
}
.categoryTabsContentInner .catIcon img {
  width: 100%;
  height: auto;
}
.categoryTabsContentInner strong {
  font-size: 22px;
  color: #fff;
  display: block;
  margin-bottom: 10px;
  font-weight: 500;
}
@media (max-width: 576px) {
  .categoryTabsContentInner strong {
    font-size: 18px;
  }
}
.categoryTabsContentInner p {
  font-size: 13px;
  line-height: 22px;
  color: #fff;
  margin-bottom: 20px;
}
.categoryTabsContentInner a {
  display: inline-block;
  border: 1px solid #fff;
  padding: 10px 30px;
  border-radius: 20px;
  background: #fff;
  font-size: 14px;
  color: #3f5c7a;
  font-weight: 500;
}
.instagramSection {
  width: 100%;
  background: #3f5c7a;
  position: relative;
}
@media (max-width: 576px) {
  .instagramSection {
    height: 200px;
  }
}
.instagramSection img {
  width: 100%;
  height: auto;
}
@media (max-width: 576px) {
  .instagramSection img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.instagramSection a {
  position: absolute;
  padding: 15px 30px;
  background: rgba(255, 255, 255, 0.7);
  color: #3f5c7a;
  font-size: 20px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.instagramSection a:hover {
  background: #2e74a3;
  color: #fff;
}
.innerPageContentWrap {
  background: #f2ede7;
}
.innerPageContentWrap .innerPageContentContainer {
  padding: 80px 15px;
}
@media (max-width: 576px) {
  .innerPageContentWrap .innerPageContentContainer {
    padding: 40px 15px;
  }
}
.jobSearchSection {
  max-width: 750px;
  margin: 0 auto;
}
@media (max-width: 767px) {
  .jobSearchSection {
    padding: 0 40px;
  }
}
.jobSearchSection strong {
  display: block;
  text-align: center;
  font-size: 28px;
  color: #ffffff;
  font-weight: 500;
  margin-bottom: 30px;
}
@media (max-width: 576px) {
  .jobSearchSection strong {
    font-size: 22px;
    margin-bottom: 15px;
  }
}
.jobSearchSection .jobSearchForm {
  position: relative;
  padding-right: 80px;
}
@media (max-width: 767px) {
  .jobSearchSection .jobSearchForm {
    padding-right: 0;
  }
}
.jobSearchSection .jobSearchForm input[type="text"],
.jobSearchSection .jobSearchForm input[type="email"],
.jobSearchSection .jobSearchForm input[type="password"],
.jobSearchSection .jobSearchForm input[type="tel"],
.jobSearchSection .jobSearchForm select {
  background: #fff;
  height: 50px;
  padding: 5px 15px 5px 15px;
  border: 1px solid #dbdad8;
  color: #5f6267;
  font-size: 15px;
  font-weight: 500;
}
.jobSearchSection .jobSearchForm input.keyword {
  background: #fff url("./images/keyword-icon.png") no-repeat center right;
}
.jobSearchSection .jobSearchForm input.location {
  background: #fff url("./images/location-icon.png") no-repeat center right;
}
.jobSearchSection .jobSearchForm input[type="search"] {
  background: #2f6f9f url("./images/search-btn.png") no-repeat center center;
  position: absolute;
  right: 0;
  top: 0;
  width: 50px;
  height: 50px;
  color: #fff;
  font-size: 0;
  padding: 0;
}
@media (max-width: 767px) {
  .jobSearchSection .jobSearchForm input[type="search"] {
    position: inherit;
    margin: 0 auto;
    width: 150px;
  }
}
.sidefilterBox {
  background: #ffffff;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 20px;
  box-shadow: 1px 1px 1px #dadada;
}
.sidefilterBox h3 {
  font-size: 16px;
  color: #3f5c7a;
  margin: 0 0 15px 0;
  padding: 0;
  position: relative;
}
.sidefilterBox h3 i {
  position: absolute;
  right: 0;
  top: 2px;
  font-size: 14px;
}
.sidefilterBox h3 .fa-minus {
  display: none;
}
.sidefilterBox h3.collapsed {
  margin: 0;
}
.sidefilterBox h3.collapsed .fa-minus {
  display: block;
}
.sidefilterBox h3.collapsed .fa-plus {
  display: none;
}
.sidefilterBox label {
  font-size: 14px;
  font-weight: 500;
  color: #5f6267;
  margin-bottom: 5px;
}
.sidefilterBox .filterInputRadio .form-check {
  margin: 0;
  padding: 0;
}
.sidefilterBox .filterInputRadio .form-check label {
  position: relative;
  padding-left: 25px;
  font-size: 14px;
  color: #5f6267;
}
.sidefilterBox .filterInputRadio .form-check input {
  position: absolute;
  left: 0;
  top: 4px;
}
.sortFilterMobile {
  display: none;
}
@media (max-width: 767px) {
  .sortFilterMobile {
    display: block;
  }
}
.jobListSecTop h2 {
  font-size: 18px;
  color: #072f65;
  margin: 10px 0 15px 0;
}
@media (max-width: 767px) {
  .jobListSecTop .sortFilter {
    display: none;
  }
}
.jobListSecTop .sortFilter label {
  font-size: 12px;
  color: #5f6267;
  text-align: right;
  margin-top: 6px;
  display: block;
}
.jobListSecTop .sortFilter input,
.jobListSecTop .sortFilter select {
  background: #fff;
  border: 1px solid #dbdad8;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  border-radius: 5px !important;
  font-size: 12px;
  font-weight: 500;
}
.jobListWrapper {
  background: #ffffff;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 20px;
  box-shadow: 1px 1px 1px #dadada;
  min-height: 400px;
}
.jobListWrapper .row .col-md-12:last-child .jobItem {
  border-bottom: none;
  margin-bottom: 0;
}
.jobListWrapper .jobItem {
  position: relative;
  border-bottom: 1px solid #eee;
  padding-bottom: 20px;
  padding-right: 210px;
  margin-bottom: 20px;
}
@media (max-width: 992px) {
  .jobListWrapper .jobItem {
    padding-right: 0px;
  }
}
.jobListWrapper .jobItem h3 {
  font-size: 18px;
  color: #072f65;
  font-weight: 500;
  margin: 0 0 15px 0;
}
.jobListWrapper .jobItem h3 span.jobId {
  font-size: 14px;
  color: #2486c8;
  font-weight: 400;
}
.jobListWrapper .jobItem p {
  font-size: 12px;
  color: #5f6267;
  margin: 0 0 15px 0;
  text-overflow: ellipsis;
}
.jobListWrapper .jobItem .jobDetailTags {
  display: block;
  margin: 0;
}
.jobListWrapper .jobItem .jobDetailTags li {
  display: inline-block;
  font-size: 12px;
  margin-right: 10px;
  color: #072f65;
  font-weight: 500;
}
.jobListWrapper .jobItem .jobDetailTags li i {
  margin-right: 5px;
}
.jobListWrapper .jobItem .jobListActions {
  position: absolute;
  right: 0;
  top: 50%;
  z-index: 9;
  transform: translate(0, -50%);
}
@media (max-width: 992px) {
  .jobListWrapper .jobItem .jobListActions {
    position: inherit;
    transform: inherit;
    margin-top: 20px;
  }
}
.filterOpenBtn {
  display: none;
  text-align: right;
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .filterOpenBtn {
    display: block;
  }
}
.filterOpenBtn a {
  margin: 0;
  padding: 10px 30px;
  background: #2c82ae;
  color: #fff;
  font-size: 12px;
  border-radius: 30px;
}
.filterOpenBtn a.open {
  background: #1c6387;
}
@media (max-width: 767px) {
  .sideFilterWrap {
    display: none;
  }
  .sideFilterWrap.open {
    display: block;
  }
}
.jobShareDropdown {
  display: inline-block;
  margin-top: 3px;
  margin-right: 10px;
}
@media (max-width: 992px) {
  .jobShareDropdown {
    float: right;
    margin-right: 40px;
  }
}
.jobShareDropdown .dropdown-toggle {
  display: inline-block;
  width: 35px;
  height: 35px;
  border: 1px solid #2f6e9e;
  font-size: 18px;
  color: #2f6e9e;
  text-align: center;
  border-radius: 50%;
  padding-top: 5px;
  position: relative;
  outline: none;
  background: #fff;
}
.jobShareDropdown .dropdown-toggle:hover {
  background: #2f6e9e;
  color: #fff;
}
.jobShareDropdown .dropdown-toggle:after {
  display: none;
}
.jobShareDropdown .dropdown-menu {
  background: none !important;
  border: none !important;
}
.jobShareDropdown .dropdown-menu ul {
  padding: 0;
  display: block;
  margin-bottom: 0;
  font-size: 0;
}
.jobShareDropdown .dropdown-menu ul li {
  display: inline-block;
  margin-bottom: 3px;
  margin: 0 2px 6px 2px;
  width: 30px;
  height: 30px;
}
.jobShareDropdown .dropdown-menu ul li a {
  display: block;
  width: 30px;
  height: 30px;
  border: 1px solid #2f6e9e;
  font-size: 16px;
  color: #2f6e9e;
  text-align: center;
  padding-top: 5px;
  position: relative;
  outline: none;
  background: #fff;
  border-radius: 50%;
}
.jobShareDropdown .dropdown-menu ul li a:hover {
  background: #2f6e9e;
  color: #fff;
}
.jobShareDropdownDp {
  float: right;
  margin: 0;
}
.pagination {
  text-align: center;
}
.pagination ul {
  display: inline-block;
  font-size: 0;
  background: #fff;
  -webkit-border-radius: 25px !important;
  -moz-border-radius: 25px !important;
  -ms-border-radius: 25px !important;
  border-radius: 25px !important;
  padding: 0;
  margin-bottom: 0;
  margin: 0 auto;
}
.pagination ul li {
  padding: 0;
  display: inline-block;
  vertical-align: top;
  border-right: 1px solid #f1eeec;
  min-width: 35px;
  text-align: center;
}
.pagination ul li:last-child {
  border-right: none;
}
.pagination ul li a {
  display: block;
  height: 40px;
  font-size: 18px;
  color: #3f5c7a;
  padding-top: 8px;
}
.pagination ul li span {
  display: block;
  padding: 8px 20px;
  font-size: 16px;
  color: #3f5c7a;
  font-weight: 400;
}
.jobDetailBanner h1 {
  display: block;
  text-align: center;
  font-size: 36px;
  color: #ffffff;
  font-weight: 500;
  margin-bottom: 20px;
}
@media (max-width: 576px) {
  .jobDetailBanner h1 {
    font-size: 22px;
  }
}
.jobDetailBanner span {
  text-align: center;
  font-size: 20px;
  color: #ffffff;
  font-weight: 400;
}
.jobDetailsLeft {
  background: #ffffff;
  padding: 30px 15px;
  border-radius: 5px;
  margin-bottom: 20px;
  box-shadow: 1px 1px 1px #dadada;
}
.jobDetailsLeft ul {
  padding: 0;
  margin: 0;
}
.jobDetailsLeft ul li {
  font-size: 16px;
  font-weight: 500;
  color: #315593;
  margin-bottom: 15px;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 15px;
}
.jobDetailsLeft ul li strong {
  font-size: 16px;
  color: #6d7173;
  font-weight: 400;
}
.jobDetailsLeft ul li:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}
.jobDetailsRight {
  background: #ffffff;
  padding: 30px 15px;
  border-radius: 5px;
  margin-bottom: 20px;
  box-shadow: 1px 1px 1px #dadada;
}
.jobDetailsRight h2 {
  font-size: 24px;
  color: #072f65;
  font-weight: 600;
  margin: 0 0 15px 0;
}
.jobDetailsRight h3 {
  font-size: 20px;
  color: #3d3f41;
  font-weight: 600;
  margin: 0 0 15px 0;
}
.jobDetailsRight p {
  font-size: 16px;
  color: #5f6267;
}
.jobDetailsRight ul {
  list-style: disc;
  padding-left: 20px;
  margin-bottom: 20px;
}
.jobDetailsRight ul li {
  font-size: 16px;
  color: #5f6267;
  margin-bottom: 5px;
}
.jobDetailsRight a {
  color: #3f5c7a;
}
.typewriter h1 {
  color: #fff;
  overflow: hidden;
  border-right: 0.15em solid orange;
  white-space: nowrap;
  margin: 0 auto;
  letter-spacing: 0.15em;
  animation: typing 3.5s steps(20, end), blink-caret 0.5s step-end infinite;
}
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: orange;
  }
}
.homeBanner {
  background: #3f5c7a;
  background-position: center center;
  background-size: cover;
}
@media (max-width: 767px) {
  .homeBanner {
    background-position: left center;
    background-size: cover;
  }
}
.bannerCaptionWrap {
  display: table;
  width: 100%;
  height: 100%;
}
.bannerCaptionWrap .bannerCaption {
  display: table-cell;
  vertical-align: middle;
  color: #fff;
  text-align: left;
  padding: 50px 15px 0 15px;
}
.bannerCaptionWrap .bannerCaption .bannerCaptionBox {
  text-align: center;
}
.bannerCaptionWrap .bannerCaption .bannerCaptionBox strong {
  font-size: 40px;
  color: #ffffff;
  font-weight: 500;
  display: block;
  margin-bottom: 10px;
}
@media (max-width: 767px) {
  .bannerCaptionWrap .bannerCaption .bannerCaptionBox strong {
    font-size: 32px;
  }
}
@media (max-width: 576px) {
  .bannerCaptionWrap .bannerCaption .bannerCaptionBox strong {
    font-size: 28px;
  }
}
.bannerCaptionWrap .bannerCaption .bannerCaptionBox p {
  display: block;
  font-size: 20px;
  font-weight: 400;
  color: #ffffff;
  margin-bottom: 15px;
}
.bannerCaptionWrap .bannerCaption .bannerCaptionBox a {
  background: #fff;
  display: inline-block;
  border: 1px solid #fff;
  font-size: 18px;
  font-weight: 500;
  color: #3f5c7a;
  padding: 12px 45px;
  border-radius: 25px;
}
.bannerCaptionWrap .bannerCaption .bannerCaptionBox a:hover {
  background: #3f5c7a;
  color: #fff;
}
.bannerWrapper {
  background: #3f5c7a;
}
.bannerWrapper .banner-slider .owl-item {
  background-size: cover;
}
.bannerWrapper .banner-slider .owl-item .item {
  background-color: #3f5c7a !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.bannerSliderStyle {
  position: relative;
}
.bannerSliderStyle .owl-dots {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%, 0);
  margin: 0;
}
.bannerSliderStyle .owl-dots .owl-dot span {
  width: 12px !important;
  height: 12px !important;
  background: #f18760 !important;
  border: none;
  margin: 0 5px !important;
}
.bannerSliderStyle .owl-dots .owl-dot.active span,
.bannerSliderStyle .owl-dots .owl-dot:hover span {
  background: #f15922 !important;
}
.bannerSliderStyle .owl-prev,
.bannerSliderStyle .owl-next {
  width: 60px;
  height: 60px;
  background: rgba(162, 193, 74, 0.8) !important;
  position: absolute;
  -webkit-border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  -ms-border-radius: 50% !important;
  border-radius: 50% !important;
  top: 50%;
  transform: translate(0, -50%);
  font-size: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  z-index: 99;
  opacity: 1 !important;
}
@media (max-width: 576px) {
  .bannerSliderStyle .owl-prev,
  .bannerSliderStyle .owl-next {
    width: 40px;
    height: 40px;
  }
}
.bannerSliderStyle .owl-prev {
  left: 30px;
}
@media (max-width: 576px) {
  .bannerSliderStyle .owl-prev {
    left: 15px;
  }
}
.bannerSliderStyle .owl-prev:before {
  width: 15px;
  height: 15px;
  border-top: 4px solid #fff;
  border-left: 4px solid #fff;
  content: "";
  display: block;
  position: absolute;
  -webkit-transform: rotate(-45deg) d;
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  margin-top: 22px;
  margin-left: 24px;
}
@media (max-width: 576px) {
  .bannerSliderStyle .owl-prev:before {
    width: 10px;
    height: 10px;
    margin-top: 16px;
    margin-left: 16px;
  }
}
.bannerSliderStyle .owl-next {
  right: 30px;
}
@media (max-width: 576px) {
  .bannerSliderStyle .owl-next {
    right: 15px;
  }
}
.bannerSliderStyle .owl-next:before {
  width: 15px;
  height: 15px;
  border-top: 4px solid #fff;
  border-right: 4px solid #fff;
  content: "";
  display: block;
  position: absolute;
  -webkit-transform: rotate(45deg) d;
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  margin-top: 22px;
  margin-left: 20px;
}
@media (max-width: 576px) {
  .bannerSliderStyle .owl-next:before {
    width: 10px;
    height: 10px;
    margin-top: 16px;
    margin-left: 14px;
  }
}
.bannerSliderStyle .owl-nav {
  margin: 0 !important;
}
.innerPageBannerWrap {
  background: #eee;
  display: block;
  width: 100%;
  padding-top: 60px;
}
.innerPageBannerWrap .innerPageBanner {
  background-position: center center;
  background-size: cover;
}
.innerPageBannerWrap .innerPageBanner .bannerContainer {
  width: 100%;
  display: table;
  height: 400px;
}
@media (max-width: 576px) {
  .innerPageBannerWrap .innerPageBanner .bannerContainer {
    height: 320px;
  }
}
.innerPageBannerWrap .innerPageBanner .bannerContainer .bannerContent {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
.bannerArrow {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 20px;
  z-index: 9;
  text-align: center;
}
.bannerArrow a {
  display: block;
  font-size: 20px;
  color: #1d1f1f;
}
.bannerArrow a .banenrArrowImg {
  width: 18px;
  display: inline-block;
}
.bannerArrow a .banenrArrowImg img {
  width: 100%;
  height: auto;
}
.bannerArrow a span {
  padding-top: 12px;
  display: block;
  text-align: center;
  font-size: 12px;
  color: #fefefe;
  font-weight: 500;
}
.bounce {
  -webkit-animation: bounce 3s ease-in-out infinite;
  -moz-animation: bounce 3s ease-in-out infinite;
  -ms-animation: bounce 3s ease-in-out infinite;
  -o-animation: bounce 3s ease-in-out infinite;
  animation: bounce 3s ease-in-out infinite;
  -webkit-animation: bounce 3s infinite;
}
@-webkit-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-6px);
  }
  60% {
    -webkit-transform: translateY(-3px);
  }
}
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-6px);
  }
  60% {
    transform: translateY(-3px);
  }
}
@-moz-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-6px);
  }
  60% {
    transform: translateY(-3px);
  }
}
#load {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999999;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
#loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
}
#loader-wrapper .centerLoaderImg {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  z-index: 1001;
  display: none;
}
#loader-wrapper .centerLoaderImg img {
  width: 100%;
  height: auto;
}
#loader-wrapper .centerLoaderCss {
  background: #2f6d9d;
  z-index: 9999;
  left: 50%;
  top: 50%;
  width: 50px;
  height: 50px;
  position: absolute;
  margin-left: -25px;
  margin-top: -25px;
  border: 8px solid #2999bd;
  border-radius: 50%;
  border-top: 8px solid #20395b;
  border-bottom: 8px solid #20395b;
  -webkit-animation: rotate 2s linear infinite;
  animation: rotate 2s linear infinite;
}
#loader-wrapper .loader-section {
  position: fixed;
  z-index: 8888;
  top: 0;
  width: 50%;
  height: 100%;
  z-index: 1000;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}
#loader-wrapper .loader-section.section-left {
  left: 0;
  background: #3f5c7a;
}
#loader-wrapper .loader-section.section-right {
  right: 0;
  background: #3f5c7a;
}
@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loaded #loader-wrapper {
  visibility: hidden;
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
  -webkit-transition: all 0.3s 1s ease-out;
  transition: all 0.3s 1s ease-out;
}
.loaded #loader-wrapper .loader-section.section-left {
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.loaded #loader-wrapper .loader-section.section-right {
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
  -webkit-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.loaded #loader-wrapper .centerLoaderImg,
.loaded #loader-wrapper .centerLoaderCss {
  opacity: 0;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.loaded .lds-ellipsis {
  opacity: 0;
  transition: all 0.5s ease-out;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 9999;
  margin-left: -32px;
  margin-top: -32px;
}
.lds-ellipsis div {
  position: absolute;
  top: 27px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #6bcae8;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 26px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 45px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}
.loginSection {
  background: #f2ede7 url("./images/login-bg.png") no-repeat center right;
  background-attachment: fixed;
  background-size: auto 100%;
  min-height: 100vh;
  padding-top: 50px;
}
@media (max-width: 992px) {
  .loginSection {
    background: #f2ede7;
  }
}
.loginLogo {
  width: 250px;
  height: 86px;
  display: block;
  margin-bottom: 30px;
}
.loginLogo img {
  width: 250px;
  height: 86px;
}
.stepsOverview {
  font-style: 16px;
  color: #3f5c7a;
  margin-bottom: 20px;
}
.loginForm {
  margin-bottom: 50px;
}
.loginForm input[type="text"],
.loginForm input[type="email"],
.loginForm input[type="password"],
.loginForm input[type="tel"],
.loginForm select {
  background: #fff;
  height: 50px;
  padding: 5px 15px 5px 15px;
  border: 1px solid #dbdad8;
  color: #5f6267;
  font-size: 15px;
  font-weight: 500;
}
.loginForm select {
  height: 50px !important;
}
.loginForm textarea {
  background: #fff;
  height: 60px;
  padding: 5px 15px 5px 15px;
  border: 1px solid #dbdad8;
  color: #5f6267;
  font-size: 15px;
  font-weight: 500;
}
.loginForm input[type="submit"] {
  background: -webkit-linear-gradient(
    left,
    #315a8e 0%,
    #2a90b8 60%,
    #2999be 100%
  );
  background: -o-linear-gradient(left, #315a8e 0%, #2a90b8 60%, #2999be 100%);
  background: -ms-linear-gradient(left, #315a8e 0%, #2a90b8 60%, #2999be 100%);
  background: -moz-linear-gradient(left, #315a8e 0%, #2a90b8 60%, #2999be 100%);
  background: linear-gradient(to right, #315a8e 0%, #2a90b8 60%, #2999be 100%);
  height: 45px;
  padding: 0 60px;
  -webkit-border-radius: 35px !important;
  -moz-border-radius: 35px !important;
  -ms-border-radius: 35px !important;
  border-radius: 35px !important;
  color: #ffffff;
  cursor: pointer;
  outline: none;
  border: none;
  margin-top: 20px;
}
.loginForm .input-group-text {
  background-color: #fff;
  font-size: 14px;
  padding-top: 3px;
}
.loginForm .addMoreFieldGroup {
  text-align: center;
  border-bottom: 1px solid #dbdad8;
  padding: 5px 0;
  margin-bottom: 20px;
}
.loginForm .addMoreFieldGroup button {
  font-size: 14px;
  color: #3f5c7a;
  font-weight: 500;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
}
.loginForm .addMoreFieldGroup button i {
  margin-right: 10px;
}
.loginForm .forgotPassword {
  display: block;
  margin-top: 4px;
  margin-bottom: 10px;
}
.loginForm .forgotPassword a {
  font-weight: 500;
  font-size: 12px;
  color: #5f6267;
}
.loginForm .forgotPassword a:hover {
  color: #316296;
}
.loginForm h3 {
  font-size: 18px;
  color: #3f5c7a;
  font-weight: 500;
}
.loginForm p {
  font-size: 16px;
  color: #3f5c7a;
}
.loginForm .uploadCvfield label {
  font-size: 18px;
  color: #3f5c7a;
  font-weight: 500;
}
.loginForm .uploadCvfield span {
  font-size: 12px;
  color: #3f5c7a;
  margin-top: 10px;
}
.loginForm .profilePicInput {
  position: relative;
  width: 120px;
  height: 120px;
  margin-bottom: 15px;
  overflow: hidden;
  float: left;
  margin-right: 10px;
}
.loginForm .profilePicInput strong {
  border: 1px solid #dbdad8;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  text-align: center;
  font-weight: 300;
}
.loginForm .profilePicInput strong span {
  display: block;
  font-size: 30px;
  color: #3f5c7a;
  margin-top: 30px;
}
.loginForm .profilePicInput strong p {
  font-size: 12px;
  color: #3f5c7a;
  font-weight: 500;
}
.loginForm .profilePicInput strong img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.loginForm .profilePicInput input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
.loginForm .profilePicDetails {
  padding-top: 40px;
}
.loginForm .profilePicDetails p {
  font-size: 12px;
  color: #3f5c7a;
  margin: 0 0 5px 0;
}
.loginForm .profilePicDetails a {
  font-size: 12px;
  color: #3f5c7a;
}
.loginForm .addMoreEduBtn {
  width: 100%;
  background: #3f5c7a;
  color: #fff;
  padding: 12px;
}
.loginForm .form-check {
  margin-bottom: 15px;
}
.loginForm .form-check label {
  font-size: 16px;
  color: #3f5c7a;
  font-weight: 500;
}
.employmentQuestions label {
  font-size: 16px;
  color: #3f5c7a;
  font-weight: 500;
}
.loginFooter {
  margin-bottom: 40px;
}
.loginFooter span {
  color: #3f5c7a;
  font-size: 14px;
}
.loginFooter p {
  display: inline-block;
  text-align: center;
  font-size: 14px;
  color: #3f5c7a;
}
.loginFooter p a {
  font-size: 14px;
  color: #3f5c7a;
  font-weight: 500;
  text-decoration: underline;
}
.stepsNav {
  background-color: #f9f6f3;
  position: fixed;
  width: 100%;
  z-index: 999;
}
.stepsNav ul {
  font-size: 0;
  margin: 0;
  border-left: 1px solid #cacfd3;
}
.stepsNav ul:after {
  display: block;
  content: "";
  clear: both;
}
.stepsNav ul li {
  display: block;
  float: left;
  vertical-align: top;
  margin-bottom: 0;
  position: relative;
}
.stepsNav ul li:first-child:before {
  width: 1000px;
  height: 80px;
  display: block;
  content: "";
  position: absolute;
  right: 50px;
  top: 0;
  background-color: #fff;
  border-top: 1px solid #cacfd3;
  border-bottom: 1px solid #cacfd3;
}
.stepsNav ul li:last-child.active {
  position: relative;
}
.stepsNav ul li:last-child.active:before {
  width: 1000px;
  height: 80px;
  display: block;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  background-color: #fff;
  border-top: 1px solid #cacfd3;
  border-bottom: 1px solid #cacfd3;
}
.stepsNav ul li:last-child.active a:after {
  background: url(./images/steps-nav-arrow-t.png) no-repeat;
}
.stepsNav ul li.completed a,
.stepsNav ul li.active a {
  background-color: #ffffff;
  border: 1px solid #cacfd3;
  border-right: none;
  border-left: none;
}
.stepsNav ul li.completed a:after,
.stepsNav ul li.active a:after {
  display: block;
  content: "";
  position: absolute;
  z-index: 99;
  right: 0px;
  top: -1px;
  width: 34px;
  height: 80px;
  background: url(./images/steps-nav-arrow-t.png) no-repeat;
}
.stepsNav ul li.completed a span,
.stepsNav ul li.active a span {
  border: 1px solid #3f5c7a;
  background-color: #3f5c7a;
  color: #fff;
}
.stepsNav ul li.active a:after {
  background: url(./images/steps-nav-arrow.png) no-repeat;
}
.stepsNav ul li a {
  border-top: 1px solid #f9f6f3;
  border-bottom: 1px solid #f9f6f3;
  display: block;
  padding: 22px 35px 15px 75px;
  position: relative;
  min-height: 80px;
  font-family: "Roboto", sans-serif;
}
@media (max-width: 1200px) {
  .stepsNav ul li a {
    padding: 22px 35px 15px 60px;
  }
}
@media (max-width: 767px) {
  .stepsNav ul li a {
    padding: 22px 30px 15px 60px;
  }
}
@media (max-width: 576px) {
  .stepsNav ul li a {
    padding: 22px 10px 15px 50px;
  }
}
.stepsNav ul li a span {
  display: block;
  width: 40px;
  height: 40px;
  border: 1px solid #c1c6cb;
  background: none;
  font-size: 16px;
  color: #3f5c7a;
  text-align: center;
  line-height: 40px;
  position: absolute;
  left: 20px;
  top: 18px;
  border-radius: 50%;
  z-index: 999;
}
@media (max-width: 1200px) {
  .stepsNav ul li a span {
    width: 30px;
    height: 30px;
    line-height: 30px;
    left: 15px;
    top: 22px;
  }
}
@media (max-width: 767px) {
  .stepsNav ul li a span {
    left: 20px;
  }
}
@media (max-width: 576px) {
  .stepsNav ul li a span {
    left: 10px;
  }
}
.stepsNav ul li a strong {
  font-size: 13px;
  line-height: 16px;
  color: #3f5c7a;
  font-weight: 500;
}
@media (max-width: 992px) {
  .stepsNav ul li a strong {
    display: none;
  }
}
.signupStepSection {
  background: url("./images/signup-object-img - Copy.png") no-repeat center
    right;
  background-attachment: fixed;
  padding-top: 120px;
  min-height: 100vh;
  background-color: #f2ede7;
}
@media (max-width: 992px) {
  .signupStepSection {
    background: #f2ede7;
  }
}
@media (max-width: 576px) {
  .signupStepSection {
    padding-top: 100px;
  }
}
.signupStepSection.signupbgTwo {
  background: url(./images/signup-object-img.png) no-repeat center right;
  background-attachment: fixed;
  padding-top: 130px;
  min-height: 100vh;
  background-color: #f2ede7;
}
.signupPrivacyPolicy h2 {
  font-size: 20px;
  color: #3f5c7a;
  font-weight: 500;
  margin-bottom: 20px;
}
.signupPrivacyPolicy p {
  font-size: 16px;
  color: #3f5c7a;
}
.signupReview {
  margin-bottom: 20px;
}
.signupReview li {
  border-bottom: 1px solid #c9c2bc;
  padding: 10px 10px 10px 10px;
}
.signupReview li span {
  font-family: "Roboto", sans-serif;
  display: block;
  margin-bottom: 6px;
  font-size: 14px;
  color: #3f5c7a;
  font-weight: 500;
}
.signupReview li strong {
  font-family: "Roboto", sans-serif;
  display: block;
  font-size: 16px;
  color: #5f6267;
  font-weight: 600;
}
.profilePicView {
  width: 120px;
  height: 120px;
}
.profilePicView img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.reviewSectionTop {
  position: relative;
  border-bottom: 3px solid #c9c2bc;
  padding: 0 20px 10px 10px;
  margin-bottom: 10px;
}
.reviewSectionTop h3 {
  font-size: 18px;
  color: #3f5c7a;
  font-weight: 500;
}
.reviewSectionTop .editOnReview {
  position: absolute;
  top: 0;
  right: 0;
  color: #3f5c7a;
  font-size: 18px;
}
.siteFooter {
  background: #f2ede7;
}
.siteFooter .footertop {
  background: #f2ede7;
  padding: 50px 15px;
  text-align: center;
}
.siteFooter .footertop .siteLogoFooter {
  max-width: 175px;
  display: inline-block;
  margin-bottom: 30px;
}
.siteFooter .footertop .siteLogoFooter img {
  width: 100%;
  height: auto;
}
.siteFooter .footertop .contactDetails {
  text-align: center;
}
.siteFooter .footertop .contactDetails ul {
  margin: 0;
  padding: 0;
}
.siteFooter .footertop .contactDetails ul li {
  font-size: 12px;
  color: #3f5c7a;
  font-weight: 500;
  margin-bottom: 10px;
}
.siteFooter .footerBottom {
  background: #eae5de;
  padding: 20px 15px 15px 15px;
}
.siteFooter .footerBottom .footerNav {
  text-align: center;
}
.siteFooter .footerBottom .footerNav ul {
  padding: 0;
  margin: 0;
}
.siteFooter .footerBottom .footerNav ul li {
  display: inline-block;
  margin: 0 6px 5px 6px;
  text-transform: uppercase;
}
.siteFooter .footerBottom .footerNav ul li a {
  color: #6e7e91;
  font-size: 11px;
  font-weight: 500;
}
.siteFooter .footerBottom .footerNav ul li a:hover {
  color: #d52950;
}
.socialIcons {
  position: fixed;
  z-index: 999;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
}
.socialIcons ul {
  margin: 0;
}
.socialIcons ul li {
  display: block;
  margin-bottom: -2px;
  border: 1px solid #ccc;
}
.socialIcons ul li a {
  display: block;
  width: 40px;
  height: 40px;
  font-size: 20px;
  background: #fff;
  color: #3f5c7a;
  text-align: center;
  padding-top: 5px;
}
.socialIcons ul li a:hover {
  background: #3f5c7a;
  color: #fff;
}
/*# sourceMappingURL=style.css.map */
input.terms_disabled {
  background: grey !important;
}
.loginForm .addMoreFieldGroup button {
  font-size: 12px;
  color: #3f5c7a;
  font-weight: 500;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
}
.is-invalid {
  color: red;
  font-size: 15px;
  margin-left: 7px;
  font-weight: 300;
}

label.currentJob {
  font-size: 15px;
  color: #3f5c7a;
  font-weight: 400;
  margin-left: 6px;
}

.jobActions {
  padding: 0;
  margin: 0;
}
.jobActions li {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
}

.jobActions li a,
.jobActions li button {
  background: #4c6b8e;
  color: #fff;
  font-size: 12px;
}

.addQuestionSection h4 {
  font-size: 20px;
  color: #4c6b8e;
  margin-bottom: 20px;
}

.addQuestionSection > li {
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 20px;
  margin-bottom: 20px;
  background: #eee;
  padding: 15px;
}

.dbBtn {
  background: #4c6b8e;
  color: #fff;
  font-size: 12px;
  padding: 5px 10px;
}

.secClose {
  display: block;
  float: right;
  font-size: 16px;
  border-radius: 5px;
  color: #dc3545;
  text-shadow: none;
  cursor: pointer;
  padding: 4px;
  border: none;
  background: none;
}

.default-button {
  background: #4c6b8e;
  color: #fff;
  font-size: 12px;
}

/* DEV html. Please change the items above that. */
.fixRenderHtml ul {
  list-style: square;
  padding-left: 15px;
}
.pagination ul li.active {
  font-size: 16px;
  background: #86c2d7;
  font-weight: 400;
}
.pagination ul li {
  cursor: pointer;
}
#next-button {
  display: none;
}

#prev-button {
  display: none;
}

.step2 #prev-button {
  display: none;
}

.description-error {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.button-style {
  background: transparent;
  border: none;
  cursor: pointer;
}

.info {
  color: #2196f3;
}
.buttonStyle {
  cursor: pointer;
}